/*
    Libraries for IE8,IE9
    Respond - A fast & lightweight polyfill for min/max-width CSS3 Media Queries (for IE 6-8, and more)
    placeholder - Placeholders.js is a JavaScript polyfill for the HTML5 placeholder attribute. has zero dependencies

    This file Will be included on all IE versions under IE10 ( IE9,IE8,IE7)
 */

// import 'Placeholders.js';

require("Placeholders.js/lib/placeholders.js");
require("html5shiv");

/*
    Shimming Modules,
    Need @this to be Window
 */
// require("imports?this=>window!Respond.js/dest/respond.src.js");
